import { Analytics } from '@vercel/analytics/react';
import {SWRConfig} from 'swr'
import '@/styles/globals.css'
import { AnimatePresence } from 'framer-motion'

import Layout from "@/components/Layout";
import Loading from "@/components/Loading";
import {useRouter} from "next/router";
import {useEffect, useState} from "react";
//import {GeolocationProvider} from "@/context/GeolocationContext";

export default function App({ Component, pageProps }) {
    const [loading, setLoading] = useState(false);
    const router = useRouter();

    useEffect(() => {
        const start = (url, { shallow }) => {
            if (!shallow) {
                setLoading(true)
            }
        }
        const end = (url, { shallow }) => {
            if (!shallow) {
                setTimeout(() => {setLoading(false)}, 1000);
            }
        }

        router.events.on("routeChangeStart", start)
        router.events.on("routeChangeComplete", end)
        router.events.on("routeChangeError", end)
        return () => {
            router.events.off("routeChangeStart", start)
            router.events.off("routeChangeComplete", end)
            router.events.off("routeChangeError", end)
        }
    }, [router.events])

    return(
      <SWRConfig value={{ fetcher: (...args) => fetch(...args).then(res => res.json()) }}>
          {/*<GeolocationProvider libraries={['geometry']}>*/}
              <AnimatePresence mode="wait" initial={false} onExitComplete={() => window.scrollTo(0, 0)}>
                  <Layout>
                      {loading ? <Loading /> : <Component {...pageProps} key={router.asPath} />}
                      <Analytics />
                  </Layout>
              </AnimatePresence>
          {/*</GeolocationProvider>*/}
      </SWRConfig>
  )
}
