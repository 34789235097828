import { motion } from "framer-motion";

import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation";

export default function Layout({children}) {
    return (
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <Navigation />
                {/*<Breadcrumb />*/}
                {children}
                <Footer />
            </motion.div>
        </>
    )
}